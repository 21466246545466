<template>
  <div
    class="element-container"
    :class="{
      draggable: draggable && !element.isEditing,
      'editing-element': element.isEditing,
      'empty-element': element.steps.length === 0,
      full: full,
      warning: element.warn,
    }"
  >
    <!-- COMPLETED STEPS VALUE-->
    <ul class="steps-container" :class="{full: full}">
      <!-- DRAGGABLE ELEMENT ICON -->

      <!-- WARNING ICON IF ELEMENT INVALID -->
      <span v-if="element.warn" class="element-icon" title="This search term is invalid">
        <s-icon type="warning" color="white" />
      </span>
      <li
        v-for="(step, stepIndex) in element.steps"
        :key="step.STEP_OPT.role"
        :const="(arrayValue = Array.isArray(step.value) ? step.value : [step.value])"
        class="item"
      >
        <span
          v-for="(value, valueIndex) in arrayValue"
          :key="valueIndex"
          class="step"
          :class="[step.STEP_OPT.role, {highlight: step.STEP_OPT.highlight}]"
        >
          <span v-if="draggable && stepIndex === 0 && !element.isEditing" class="draggable-icon inline-block">
            <s-icon type="drag-indicator" color="white" size="10" />
          </span>
          <span
            :class="{'editable-step': editable}"
            data-cy="element-item"
            @click.self="elementClicked(stepIndex, valueIndex)"
          >
            {{ getStepLabel(value, step) }}
          </span>
          <!-- display the separator if the value is'nt the last one or if there are multiple value expected -->
          <span
            v-if="
              valueIndex < arrayValue.length - 1 ||
              (arrayValue.length > 0 &&
                Number.isInteger(step.STEP_OPT.multiple) &&
                arrayValue.length < step.STEP_OPT.multiple)
            "
          >
            {{ step.STEP_OPT.separator ? step.STEP_OPT.separator : ', ' }}
          </span>
          <span
            v-if="stepIndex === element.steps.length - 1 && removable === true && !element.isEditing"
            class="remove-icon inline-block"
            role="button"
          >
            <s-icon
              type="cross"
              color="white"
              :on-click="
                () => {
                  $emit('remove-element');
                }
              "
              size="10"
            />
          </span>
        </span>
      </li>

      <!-- REMOVE ELEMENT BUTTON-->
    </ul>

    <!-- DISPLAYABLE COMPONENTS: Select, Text, Datetime & Number inputs-->
    <div v-if="element.isEditing && !processingStep" ref="input" class="step-input">
      <s-input-select
        v-if="currentStepOption && currentStepOption.field === 'select'"
        v-model="fieldValue"
        v-model:search-string="selectSearchString"
        name="select"
        class="inside-search"
        :options="currentStepOption.multiple ? multiSelectOptions : computedSelectOptions"
        :required="currentStepOption.required || currentStepOption.field === 'select'"
        :custom-label="currentStepOption.customLabel"
        :placeholder="currentStepOption.placeholder"
        :on-blur="onBlur"
        :on-focus="onFocus"
        :on-paste="onPaste"
        :disabled="!editable"
        :overflow="overflow"
        data-cy="input-search-element"
        :size="size"
        @mounted="selectInputMounted"
        @keydown="onKeyDownSelect"
        @label-on-top="labelOnTop"
      ></s-input-select>

      <s-input-text
        v-else-if="currentStepOption && currentStepOption.field === 'text'"
        v-model="fieldValue"
        name="text"
        :pattern="currentStepOption.pattern"
        :placeholder="currentStepOption.placeholder"
        :on-key-pressed="onKeyDownText"
        :on-blur="onBlur"
        :on-focus="onFocus"
        :on-paste="onPaste"
        data-cy="input-search-element"
        :size="size"
      ></s-input-text>

      <s-input-datetime
        v-else-if="currentStepOption && currentStepOption.field === 'date'"
        v-model="fieldValue"
        name="datetime"
        :on-key-pressed="onKeyDownDate"
        :from="currentStepOption.min"
        :to="currentStepOption.max"
        :placeholder="currentStepOption.placeholder"
        :on-blur="onBlur"
        :on-focus="onFocus"
        :overflow="overflow"
        data-cy="input-search-element"
        :size="size"
      />

      <s-input-number
        v-else-if="currentStepOption && currentStepOption.field === 'number'"
        v-model="fieldValue"
        name="number"
        :type="currentStepOption.type"
        :min="currentStepOption.min"
        :max="currentStepOption.max"
        :step="currentStepOption.step"
        :pattern="currentStepOption.pattern"
        :placeholder="currentStepOption.placeholder"
        :on-key-pressed="onKeyDownNumber"
        :on-blur="onBlur"
        :on-focus="onFocus"
        :on-paste="onPaste"
        data-cy="input-search-element"
        :size="size"
      ></s-input-number>
    </div>
  </div>
</template>

<script>
  import {valueMatchPattern, isEmpty, deepExtend} from '@veasel/core/tools';

  export default {
    name: 's-element',

    props: {
      elementToEdit: Object,
      options: Object,
      draggable: {
        type: Boolean,
        default: true,
      },
      full: {
        type: Boolean,
        default: false,
      },

      editable: {
        type: Boolean,
        default: true,
      },

      removable: {
        type: Boolean,
        default: false,
      },

      overflow: {
        description: 'Indicates whether the input dropdowns should escape from scrollable overflow containers',
        type: String,
        values: ['auto', 'enabled', 'disabled'],
        validator: (v) => ['auto', 'enabled', 'disabled'].includes(v),
        default: 'auto',
      },

      size: {
        type: String,
      },
    },

    data: () => ({
      // Index in 'element.steps' where the current element step object is pushed
      stepIndex: 0,

      // Index in 'element.steps.value' if multiple where the current value is pushed
      valueIndex: 0,
      currentStepOption: {
        field: '',
      },

      // Value of the current input field
      fieldValue: undefined,

      // If we are processing the next step, we mark this as true, hide everything while we change the fieldValue, then show it again
      processingStep: false,

      // When the input accepts multiple values, stash the previously entered values here
      fieldValues: [],

      // Indicates the cause of the blur event when the handler is called
      inputBlurContext: 'user-submit-input',

      // message tooltip
      message: undefined,
      displayError: false,
      displayHint: false,

      // Used to stash the value and steps of the element when editing begins, so that it can be restored if the edit is cancelled
      editingElValue: undefined,
      editingElSteps: [],

      // String that has been typed into the s-input-select
      selectSearchString: '',

      // Tooltip position
      tooltipBottom: 0,
      tooltipRight: 0,

      lastPasteEventTimestamp: false,
    }),

    computed: {
      element: {
        get: function () {
          return this.elementToEdit;
        },
        set: function (newData) {
          this.$emit('update:element', newData);
        },
      },
      // Construct the array of options to be presented by the select input
      computedSelectOptions() {
        let selectOptions = [];
        // Only necessary if the current step is a 'select' field
        if (this.currentStepOption && this.currentStepOption.field === 'select') {
          if (this.currentStepOption.multiple) {
            // If multiple values are supported, filter out any values already entered
            selectOptions = this.currentStepOption.options.filter(
              (opt) => !this.fieldValues.map((v) => JSON.stringify(v)).includes(JSON.stringify(opt))
            );
          } else {
            selectOptions = [...this.currentStepOption.options];
          }
        }

        return selectOptions;
      },

      // If the select input accepts multiple values (e.g. enum with 'in' operator) create the select array with extra 'Done' option
      multiSelectOptions() {
        if (this.fieldValues.length === 0 || this.computedSelectOptions.length === 0) {
          return this.computedSelectOptions;
        } else {
          // 'Done' option should always be first so it is selected if the user pressed Enter
          return [{key: '__done', label: '(Done)'}, ...this.computedSelectOptions];
        }
      },

      // True if the tooltip should be visible
      showTooltip() {
        return this.element.isEditing && (this.displayError || this.displayHint);
      },

      // Style to apply to the tooltip for positioning
      tooltipStyle() {
        return {
          bottom: this.tooltipBottom + 'px',
          right: this.tooltipRight + 'px',
        };
      },
    },

    methods: {
      selectInputMounted() {
        // Add a keydown listener to the select input manually, as it has no event for this by default
        if (this.$refs.input) {
          this.$refs.input.querySelector('input').addEventListener('keydown', this.onKeyDownSelect);
        }

        // Reset the values tracking what has been typed into the select input
        this.selectSearchString = '';
      },

      // Focus the current input component as though the user had clicked on it
      focusInput() {
        this.$nextTick(() => {
          if (this.element.isEditing) {
            if (this.$refs.input) {
              this.$refs.input.querySelector('input').focus();
            }
          }
        });
      },

      // When the input component gains focus
      onFocus() {
        this.checkForError();
      },

      // Defocus the input, as though the user had clicked elsewhere. In some cases submits the entered value.
      blurInput(inputBlurContext) {
        this.inputBlurContext = inputBlurContext;
        this.$nextTick(() => {
          if (this.$refs.input) {
            this.$refs.input.querySelector('input').blur();
          }
        });
      },

      // Called when the input is de-focused. This may indicated several things, including submission of the input
      onBlur() {
        // Hide the hint and error
        this.displayHint = false;
        this.displayError = false;
        switch (this.inputBlurContext) {
          case 'user-submit-input':
            // If a maximum number of values is set, and we have not yet reached that number, add this value to the list
            if (Number.isInteger(this.currentStepOption.multiple) && this.currentStepOption.multiple) {
              this.addMultipleValue();
              break;
            }
            // If the input field is select or date, and multiple values are supported, add this value to the list
            if (
              ['select', 'date'].includes(this.currentStepOption.field) &&
              this.currentStepOption.multiple &&
              this.multiSelectOptions.length > 2 &&
              this.fieldValue
            ) {
              this.addMultipleValue();
              break;
            }

            // If no value has been entered the edit has been cancelled, so restore from the stashed steps
            if (
              this.fieldValue === undefined &&
              this.editingElSteps &&
              this.editingElSteps.length > 0 &&
              this.element.steps.length !== this.editingElSteps.length
            ) {
              this.element.steps = deepExtend([], this.editingElSteps);
              this.finishEditing();
              break;
            }

            // In all other cases, the user is submitting input, so move to the next step
            this.toNextStep();
            break;

          case 'user-press-backspace':
            // This de-focus was caused by the user pressing backspace, so go back a step
            this.toPreviousStep();
            break;

          case 'user-press-escape':
            // If stashed steps are available, the user is cancelling an edit, so restore them
            if (this.editingElSteps && this.editingElSteps.length > 0) {
              this.element.steps = deepExtend([], this.editingElSteps);
              this.finishEditing();
              break;
            }

            // Otherwise the user is cancelling a new element, so remove it
            this.$emit('remove-element');
            break;
        }

        // Reset the context as the next event may be organic

        return (this.inputBlurContext = 'user-submit-input');
      },

      onPaste(event) {
        if (!event._vts || this.lastPasteEventTimestamp === event._vts) {
          return;
        }
        this.lastPasteEventTimestamp = event._vts;
        // Prevent the standard paste behaviour
        event.preventDefault();

        const valuesToAdd = [];

        // Read the clipboard
        const clipboard = event.clipboardData || window.clipboardData;
        const replacedData = clipboard.getData('text');

        // Values from Excel selection are extracted via '\n', '\t. ',' splits
        const cellValues = replacedData.split('\n').join('\t').split('\t').join(',').split(',');

        for (let i = 0; i < cellValues.length; i++) {
          const cellValue = cellValues[i];

          if (cellValue) {
            // We want to include the current value in there, but only for the first cell
            let currentValue = '';
            if (i === 0 && this.fieldValue) {
              currentValue = this.fieldValue;
            }
            this.fieldValue = this.getValueFromPastedCell(cellValue, currentValue);

            if (
              valueMatchPattern(this.fieldValue, this.currentStepOption.pattern) &&
              !valuesToAdd.includes(this.fieldValue) &&
              !this.fieldValues.includes(this.fieldValue)
            ) {
              valuesToAdd.push(this.fieldValue);
            }
          }
        }

        // Add each value
        valuesToAdd.forEach((val) => {
          this.fieldValue = val;
          this.addMultipleValue();
        });
      },

      getValueFromPastedCell(value, currentValue) {
        let formatedValue = value.trim();
        let findOption = false;

        switch (this.currentStepOption.field) {
          case 'number':
            formatedValue = parseFloat(currentValue.toString() + formatedValue);
            break;

          case 'select':
            for (let i = 0; !findOption && i < this.computedSelectOptions.length; i++) {
              const opt = this.computedSelectOptions[i];
              // ex: if key or label equals the value
              if (Object.values(opt).includes(formatedValue)) {
                findOption = true;
                formatedValue = opt;
              }
            }

            if (!findOption) {
              formatedValue = undefined;
              Notify.warning('"' + value + '" is not in the list of available select options for the field');
              console.warn(
                '"' +
                  value +
                  '" is not in the list of available select options for the field "' +
                  this.currentStepOption.role +
                  '" (hint: "' +
                  this.currentStepOption.hint +
                  '")'
              );
            }
            break;

          default:
            formatedValue = currentValue + formatedValue;
            break;
        }

        return formatedValue;
      },

      // Catch keyboard events for the date input
      onKeyDownDate(event) {
        switch (event.code) {
          case 'Backspace':
            // If a date value is in place, erase it entirely
            if (this.fieldValue != null) {
              this.fieldValue = null;
            } else {
              // If no value is in place, de-focus the input and move back a step
              this.blurInput('user-press-backspace');

              // Prevent default to avoid erasing the last character of the previous step
              event.preventDefault();
            }
            break;

          case 'Comma':
          case 'Tab':
            if (this.fieldValue) {
              // Do not add comma to text field / tab onward
              event.preventDefault();

              // Attempt to add the inputted value to the values list
              this.addMultipleValue();
            }
            break;
        }
      },

      // Catch keyboard events on the select input
      onKeyDownSelect(event) {
        switch (event.code) {
          case 'Backspace':
            // If there are characters left to be deleted, do nothing, otherwise de-focus and move back a step
            if (this.selectSearchString.length === 0) {
              this.blurInput('user-press-backspace');

              // Prevent default to avoid erasing the last character of the previous step
              event.preventDefault();
            }
            break;

          case 'Escape':
            // If this is a mutiple select we want to de focus
            if (this.currentStepOption.multiple) {
              this.blurInput('user-press-escape');
            } else {
              // Cheeky hack: we know a blur event is incoming, so before it happens switch the context to indicate that escape was pressed
              this.inputBlurContext = 'user-press-escape';
            }
            break;
        }
      },

      // Catch keyboard events on the text input
      onKeyDownText(event) {
        switch (event.code) {
          case 'Enter':
          case 'NumpadEnter':
            // If a value has been entered, finish the step by defocusing (will add the value and complete the element)
            if (this.fieldValue && this.fieldValue.length > 0) {
              this.blurInput('user-submit-input');
            } else if (this.fieldValues.length > 0) {
              // If there is no value entered, as long there is atleast one other value, we can also complete the step
              this.blurInput('user-submit-input');
            } else {
              // If no value is entered and no previous values have been added, warn the user
              this.displayErrorMessage('Must enter at least one value');
            }
            break;

          case 'Backspace':
            // If there are characters left to be deleted, do nothing, otherwise de-focus and move back a step
            if (!this.fieldValue || this.fieldValue.length === 0) {
              this.blurInput('user-press-backspace');

              // Prevent default to avoid erasing the last character of the previous step
              event.preventDefault();
            }
            break;

          case 'Comma':
          case 'Tab':
            if (this.fieldValue && this.fieldValue.length !== 0) {
              // Do not add comma to text field / tab onward
              event.preventDefault();

              // Attempt to add the inputted value to the values list
              this.addMultipleValue();
            }
            break;

          case 'Escape':
            // De-focus
            // this.blurInput('user-submit-input');
            this.blurInput('user-press-escape');
            break;
        }
      },

      // Catch keyboard events on the number input
      onKeyDownNumber(event) {
        switch (event.code) {
          case 'Enter':
          case 'NumpadEnter':
            if (this.fieldValue !== undefined || this.fieldValues.length > 0) {
              this.blurInput('user-submit-input');
            } else {
              this.displayErrorMessage('Must enter at least one value');
            }
            break;

          case 'Backspace':
            // If the input has been fully deleted fieldValue will be undefined. De-focus the input and go back a step
            if (this.fieldValue === undefined) {
              this.blurInput('user-press-backspace');

              // Prevent default to avoid erasing the last character of the previous step
              event.preventDefault();
            }
            break;

          case 'Comma':
          case 'Tab':
            if (this.fieldValue !== undefined) {
              // Do not add comma to text field / tab onward
              event.preventDefault();

              this.addMultipleValue();
            }
            break;

          case 'Escape':
            // this.blurInput('user-submit-input');
            this.blurInput('user-press-escape');
            break;
        }
      },

      // Check if the field value is valid, show or hide the error message
      checkForError() {
        if (this.fieldValueIsInError()) {
          // Value validation failed (pattern provided in step options)
          this.displayErrorMessage(this.currentStepOption.errorMessage || 'Value input error');
        } else if (
          this.currentStepOption &&
          this.currentStepOption.multiple &&
          this.fieldValues.includes(this.fieldValue)
        ) {
          // Value already included the set of multiple values
          this.displayErrorMessage('This value has already been added');
        } else {
          // No error, clear
          this.displayError = false;

          if (this.currentStepOption && this.currentStepOption.hint) {
            this.message = this.currentStepOption.hint;
            this.displayHint = true;
          }
        }
      },

      elementClicked(stepIndex, valueIndex) {
        if (this.editable === true) {
          this.editStepValue(stepIndex, valueIndex);
        }
      },

      // When the user presses backspace from the leading empty element to edit the preceding one
      editLastValue() {
        const lastStep = this.element.steps.length - 1;

        if (this.element.steps[lastStep]) {
          const lastValue = Array.isArray(this.element.steps[lastStep].value)
            ? this.element.steps[lastStep].value.length - 1
            : 0;

          this.editStepValue(lastStep, lastValue);
        }
      },

      // Start editing at a specific step
      editStepValue(stepIndex, valueIndex) {
        this.stepIndex = stepIndex;
        this.valueIndex = valueIndex;
        this.editingElSteps = deepExtend([], this.element.steps);
        this.element.steps = this.element.steps.slice(0, stepIndex + 1);
        this.element.isEditing = true;
        this.updateStepInfos();

        if (this.currentStepOption && this.currentStepOption.field === 'select') {
          this.fieldValue = undefined;
        }
      },

      // Attempt to progress to the next step
      toNextStep() {
        // Ensure there is a value entered
        if (this.hasValue(this.fieldValue) || (Array.isArray(this.fieldValues) && this.fieldValues.length > 0)) {
          // Validate value
          if (!this.fieldValueIsInError()) {
            // Check that the value has not already been added
            if (this.currentStepOption.multiple && this.fieldValues.includes(this.fieldValue)) {
              this.displayErrorMessage('This value has already been added');
              this.focusInput();

              return;
            }

            // Get the value
            let addedValue = this.fieldValue;
            // value need to be saved in array
            if (this.currentStepOption.multiple) {
              if (this.hasValue(this.fieldValue) && this.fieldValue.key !== '__done') {
                this.fieldValues.splice(this.valueIndex, 0, this.fieldValue);
                this.valueIndex++;
              }
              addedValue = this.fieldValues;
            }

            // if not undefined (fix specific case when user paste more values than available select options)
            if (addedValue !== undefined && addedValue !== null) {
              this.element.steps[this.stepIndex] = deepExtend(
                {},
                {
                  STEP_OPT: this.currentStepOption,
                  value: addedValue,
                }
              );
            }

            this.goToNextStep();
          } else {
            this.focusInput();
          }
        }
      },

      // Actually go to the next step
      goToNextStep() {
        this.currentStepOption = this.getNextStepOptions();

        if (this.isCompleted()) {
          this.finishEditing();
        } else {
          this.processingStep = true;
          this.$nextTick().then(() => {
            this.stepIndex++;
            this.fieldValue = undefined;
            this.fieldValues = [];
            this.valueIndex = 0;
            this.processingStep = false;
            this.focusInput();
          });
        }
      },

      // Go back a step
      toPreviousStep() {
        if (this.currentStepOption.multiple && this.fieldValues.length > 0) {
          // go back to previous or the last element
          this.valueIndex = this.valueIndex === 0 ? this.fieldValues.length - 1 : this.valueIndex - 1;
          this.element.steps[this.stepIndex] = deepExtend(
            {},
            {
              STEP_OPT: this.currentStepOption,
              value: this.fieldValues,
            }
          );

          if (this.currentStepOption && this.currentStepOption.field === 'select') {
            // For a select (enum) we want to update the step info first, then erase the value
            this.updateStepInfos();
            this.fieldValue = undefined;
          } else {
            // Otherwise we want to preserve the value, so copy it first, then update the step info
            this.fieldValue = this.fieldValues[this.valueIndex];
            this.updateStepInfos();
          }

          this.focusInput();
        } else if (this.element.steps[this.stepIndex - 1]) {
          // if there is a previous step
          //  delete the current step only if it has been added
          if (this.stepIndex === this.element.steps.length - 1) {
            this.element.steps.pop();
          }

          // go to previous step
          this.stepIndex--;

          this.updateStepInfos();

          if (this.currentStepOption && this.currentStepOption.field === 'select' && !this.currentStepOption.multiple) {
            this.fieldValue = undefined;
            this.editingElValue = this.fieldValue;
            this.focusInput();
          }
        } else {
          this.$emit('previous-element');
        }
      },

      getNextStepOptions() {
        return deepExtend(
          {},
          this.currentStepOption ? this.currentStepOption['NEXT_STEP'] : undefined,
          this.fieldValue ? this.fieldValue['NEXT_STEP'] : undefined
        );
      },

      updateStepInfos() {
        this.currentStepOption = this.element.steps[this.stepIndex].STEP_OPT;
        const currentValue =
          this.currentStepOption && this.currentStepOption.field === 'number' && !this.currentStepOption.multiple
            ? parseFloat(this.element.steps[this.stepIndex].value)
            : this.element.steps[this.stepIndex].value;

        if (Array.isArray(currentValue)) {
          this.fieldValues = currentValue;
          this.fieldValue = this.fieldValues[this.valueIndex];
          this.fieldValues.splice(this.valueIndex, 1);
        } else {
          this.fieldValue = currentValue;
        }

        this.editingElValue = this.fieldValue;

        // In some cases the final step needs to be removed, to avoid having the value under edit being displayed twice (once as a value and once in the input component)
        if (
          this.stepIndex === this.element.steps.length - 1 &&
          ((this.currentStepOption.multiple && this.fieldValues.length === 0) || !this.currentStepOption.multiple)
        ) {
          this.element.steps.pop();
        }

        this.focusInput();
      },

      // Attempt to add a value to the list of values when multiple values are allowed
      addMultipleValue() {
        // Value must not be undefined or empty, and must not be an array
        if ([undefined, null, ''].includes(this.fieldValue) || Array.isArray(this.fieldValue)) {
          return;
        }

        // The value must not be invalid. If it is, refocus the input
        if (this.fieldValueIsInError()) {
          this.focusInput();
          return;
        }

        // In the case of a multiple value select input, the special '__done' value indicates that input is complete
        if (this.fieldValue.key === '__done') {
          // Complete this element and move to the next
          this.finishEditing();
          return;
        }

        // Check that the value is within the maximum allowed values, or if no max that multiple values are allowed
        if (
          Number.isInteger(this.currentStepOption.multiple)
            ? this.fieldValues.length < this.currentStepOption.multiple
            : this.currentStepOption.multiple
        ) {
          // Check the value is not already in the list
          if (this.fieldValues.includes(this.fieldValue)) {
            this.displayErrorMessage('This value has already been added');
            this.focusInput();
          } else {
            this.fieldValues.splice(this.valueIndex, 0, this.fieldValue);
            this.valueIndex++;
            this.element.steps[this.stepIndex] = deepExtend(
              {},
              {
                STEP_OPT: this.currentStepOption,
                value: this.fieldValues,
              }
            );
            this.fieldValue = undefined;

            // if maximum of multiple values
            if (
              Number.isInteger(this.currentStepOption.multiple) &&
              this.fieldValues.length === this.currentStepOption.multiple
            ) {
              this.finishEditing();
            } else {
              this.focusInput();
            }
          }
        }
      },

      finishEditing() {
        this.currentStepOption = {
          field: '',
        };
        this.element.isEditing = false;
      },

      // Validate the fieldValue against relevent patterns
      fieldValueIsInError() {
        return !valueMatchPattern(this.fieldValue, this.currentStepOption ? this.currentStepOption.pattern : undefined);
      },

      // Show an error message above the input
      displayErrorMessage(error) {
        this.message = error;
        this.displayHint = false;
        this.displayError = true;
      },

      // Return the appropriate label for a given value and step
      getStepLabel(value, step) {
        if (typeof value == 'object') {
          return typeof step.STEP_OPT.customLabel == 'function' ? step.STEP_OPT.customLabel(value) : value.label;
        } else {
          return typeof step.STEP_OPT.customLabel == 'function' ? step.STEP_OPT.customLabel(value) : value;
        }
      },

      // Indicates whether we have reached the end of the available steps
      isCompleted() {
        return isEmpty(this.currentStepOption);
      },

      // Determine whether a value would be considered 'not empty'
      hasValue(value) {
        return (
          (![undefined, null, ''].includes(value) && !Array.isArray(value)) ||
          (Array.isArray(value) && value.length > 0)
        );
      },

      labelOnTop(onTop) {
        this.$emit('label-on-top', onTop);
      },
    },
    emits: ['label-on-top', 'remove-element', 'previous-element', 'update:element'],
    created() {
      // if the element is filled, displays it as completed else displays the first step input
      this.currentStepOption = this.element.steps.length > 0 ? undefined : this.options['NEXT_STEP'];
    },

    watch: {
      // Each time the field value changes, check for the need to display or clear the error message
      fieldValue() {
        this.checkForError();
      },

      // Watch for changes to the options prop and update the current step options in response
      options() {
        this.currentStepOption = this.options['NEXT_STEP'];
      },

      // When the tooltip is shown, recalculate position
      showTooltip() {
        if (this.showTooltip) {
          const bcr = this.$el.parentNode.getBoundingClientRect();
          // We want to set the botton position, rather than the top, because we don't know the height/amount of text the tooltip will have.
          this.tooltipBottom = window.innerHeight - bcr.top;
          this.tooltipRight = window.innerWidth - bcr.right;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@veasel/core';

  ::v-deep(.multiselect) {
    // not display is-required style (orange border)

    min-height: 2rem;

    input {
      border: none;
    }
  }

  ::v-deep(.multiselect__tags) {
    min-height: 2rem;
    cursor: pointer;
  }

  ::v-deep(.s-input .s-input-group-field) {
    background-color: transparent !important;
    outline: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border-width: 0 !important;
  }

  .element-container {
    user-select: none;
    position: relative;
    display: flex;
    max-width: 100%;
    margin-left: get-spacing('s');
    // margin-right: get-spacing('s');
    &:first-child {
      margin-left: 0;
    }

    &.draggable {
      cursor: grab;
    }

    &:not(.draggable) {
      user-select: none;
      cursor: default;
    }
  }

  .element-container.empty-element:last-child,
  .element-container.editing-element:not(.empty-element):last-child {
    flex-grow: 1;
  }

  .element-container.editing-element {
    padding: 0 0 0 15px;
    transform: unset !important;
  }

  .element-container.empty-element {
    margin-left: 0;
    margin-right: 0;
    padding: 0;
    background-color: transparent;

    .steps-container {
      display: none;
    }

    .step-input {
      width: 100%;

      ::v-deep(.s-input.disabled) {
        height: 36px;
      }
    }
  }

  .element-container.sortable-chosen {
    cursor: grabbing;
  }

  .element-container.warning {
    background-color: var(--warning);
    padding-left: get-spacing('s');
    padding-right: get-spacing('s');

    .element-icon {
      margin-right: get-spacing('xs');
    }
  }

  .steps-container {
    display: flex;
    align-items: center;

    li {
      padding: 0;
      display: inline-block;
    }

    li .step {
      display: inline-block;
      position: relative;
      height: rem(16px);
      padding: rem(2px) rem(3px) rem(3px) rem(3px);
      border: 1px solid var(--active);
      background-color: var(--active);
      @include caption-alt;

      color: var(--background-main);

      &.field {
        background-color: var(--active);
        @include caption;

        color: var(--background-main);
      }

      &.value {
        background-color: var(--background-main);
        @include caption-alt;

        padding-right: 0.5rem;
        padding-left: 0.5rem;
      }

      &.aggregation_fun {
        padding-left: 0.5rem;
      }

      .editable-step {
        cursor: text;
      }
    }

    li:first-child .step:first-child {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }

    li:last-child .step:not(:last-child) {
      border-right-width: 0;
      padding-right: 0.1rem;

      .remove-icon {
        display: none;
      }
    }

    li:last-child .step:not(:first-child) {
      border-left-width: 0;
      padding-left: 0.1rem;
    }

    li:last-child .step:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }

    li:last-child .step:not(.field):last-child {
      background-color: var(--background-main);
      color: var(--main-dark);

      .remove-icon {
        ::v-deep(svg) {
          fill: var(--main-dark);
        }

        &:hover ::v-deep(svg) {
          fill: var(--error);
        }
      }

      &.operator {
        padding-left: get-spacing('s');
      }
    }

    .draggable-icon {
      position: relative;
      top: rem(1px);
      padding-right: get-spacing('xs');
      cursor: grabbing;
      fill: inherit;
    }

    .remove-icon {
      position: relative;
      padding: 0 0.25em 0 0.5em;

      ::v-deep(svg) {
        cursor: pointer;
      }

      &:hover ::v-deep(svg) {
        cursor: pointer;
        fill: var(--error);
      }
    }
  }

  .step-input {
    display: inline-block;
    min-width: 200px;
    flex-grow: 1;

    ::v-deep(input) {
      min-height: 2rem;
    }
  }

  .input-disabled {
    .step {
      opacity: 0.5;
    }

    .step-input {
      display: none;
    }
  }

  .input-label-inside {
    .steps-container {
      li .step {
        display: inline-block;
        position: relative;
        margin-top: 0.625rem;
        height: 1rem;
        padding: 0 0.2rem 0 0.2rem;
        font-size: var(--base-small-font-size);
      }
    }

    &.input-disabled {
      li .step {
        margin-top: 0.75rem;
      }
    }
  }

  .small {
    .steps-container {
      li .step {
        display: inline-block;
        position: relative;
        margin-top: 0;
        height: 1rem;
        padding: 0 0.2rem 0 0.2rem;
        font-size: var(--base-small-font-size);
      }
    }

    &.input-disabled {
      li .step {
        margin-top: 0.2rem;
      }
    }
  }
</style>
