import API from '@/services/api.js';
import EventSockets from '@/config/eventSockets.js';

import {isEmpty} from '@veasel/core/tools';

// HTTPS or HTTP
const wsProtocol = document.location.protocol.includes('https') ? 'wss' : 'ws';
const wsDebugMode = import.meta.env.NODE_ENV !== 'production';

export default {
  data: () => {
    return {
      $_webSockets_connections: {},
      $_webSockets_eventSources: {},
      $_webSockets_closeConnection: {},
      $_webSockets_subscribedScreens: [],
      $_webSockets_subscribedEvents: {},

      reconnectionsEnabled: false,
      reconnectAttempts: 0,
      connectionStates: ['connecting', 'open', 'closing', 'closed'],
    };
  },

  computed: {
    validEvents() {
      const events = [];
      Object.keys(EventSockets).forEach(function (screenKey) {
        Object.keys(EventSockets[screenKey]).forEach(function (eventKey) {
          Object.keys(EventSockets[screenKey][eventKey]).forEach(function (eventNameKey) {
            events.push(eventNameKey);
          });
        });
      });
      return events;
    },
  },

  methods: {
    webSocketsSubscribe: function (screen, enableThroughTesting = false, fallbackOverride, transmitMessage) {
      if (window.Cypress && !enableThroughTesting) {
        console.warn('[SUADE] WebSockets not running due to Cypress test');
        return false;
      }

      if (!Object.prototype.hasOwnProperty.call(EventSockets, screen)) {
        console.warn('[SUADE] WebSocket screen is invalid');
        return false;
      }

      const webSocketsRequiredByScreen = Object.keys(EventSockets[screen]);

      // Have we already subscribed to this screen
      if (this.$data.$_webSockets_subscribedScreens.includes(screen)) {
        return false;
      }

      this.$data.$_webSockets_subscribedScreens.push(screen);

      // Loop through each websocket we need to connect to
      webSocketsRequiredByScreen.forEach((WSEndpoint) => {
        const connection = this.$data.$_webSockets_connections[WSEndpoint];

        // This is not the same guard $_webSockets_subscribedScreens. This is per EventSocket per connection
        if (connection?.connectedScreens.has(screen)) {
          return;
        }

        connection?.connectedScreens.add(screen);

        if (connection || this.isOpenOrConnecting()) {
          // Remove this as a connection to cancel
          if (typeof this.$data.$_webSockets_closeConnection[WSEndpoint] === 'number') {
            clearTimeout(this.$data.$_webSockets_closeConnection[WSEndpoint]);
            this.$data.$_webSockets_closeConnection[WSEndpoint] = null;
          }

          if (
            connection &&
            typeof transmitMessage !== 'undefined' &&
            this.connectionStates[connection?.socket.readyState] !== 'connecting'
          ) {
            connection.socket?.send(transmitMessage);
          }
        } else if (isEmpty(this.$_webSockets_connections)) {
          this.$_webSockets_connect(WSEndpoint, null, fallbackOverride, transmitMessage, screen);
        }
      });

      return true;
    },

    isOpenOrConnecting(connection) {
      return (
        typeof connection?.socket === 'object' &&
        (this.connectionStates[connection?.socket.readyState] === 'open' ||
          // If `connecting` state fails an `onClose` event will be emitted and handled later
          this.connectionStates[connection?.socket.readyState] === 'connecting')
      );
    },

    webSocketsSubscribeEvent: function (screen, event, func) {
      if (typeof this.$data.$_webSockets_subscribedEvents[screen] === 'undefined') {
        this.$data.$_webSockets_subscribedEvents[screen] = {};
      }
      this.$data.$_webSockets_subscribedEvents[screen][event] = func;
    },

    webSocketsUnsubscribe(screen) {
      if (this.$data.$_webSockets_subscribedScreens.includes(screen)) {
        const index = this.$data.$_webSockets_subscribedScreens.indexOf(screen);
        this.$data.$_webSockets_subscribedScreens.splice(index, 1);

        if (typeof this.$data.$_webSockets_subscribedEvents[screen] !== 'undefined') {
          delete this.$data.$_webSockets_subscribedEvents[screen];
        }
      }

      try {
        // Loop through and mark connections to close - but delay it in case we are loading a new page that has a webSocket
        const connectionsToClose = Object.keys(EventSockets[screen]);
        connectionsToClose.forEach((WSEndpoint) => {
          // Need to remove ref in connectedScreens if unsubscribing from messages
          // However, we don't want to close the socket connection if we are still subscribed on another screen
          const hasScreen = this.$data.$_webSockets_connections[WSEndpoint]?.connectedScreens.has(screen);

          if (hasScreen && this.$data.$_webSockets_connections[WSEndpoint]?.connectedScreens.size === 1) {
            if (typeof this.$data.$_webSockets_connections[WSEndpoint]?.socket === 'object') {
              this.$data.$_webSockets_closeConnection[WSEndpoint] = setTimeout(() => {
                this.$data.$_webSockets_connections[WSEndpoint].socket?.close();

                if (typeof this.$data.$_webSockets_eventSources[WSEndpoint] === 'object') {
                  this.$data.$_webSockets_eventSources[WSEndpoint]?.close();
                }

                delete this.$data.$_webSockets_connections[WSEndpoint];
              }, 2000);
            }
          }

          // Only remove ref after logic to check socket in the above condition has run
          if (hasScreen) {
            this.$data.$_webSockets_connections[WSEndpoint]?.connectedScreens.delete(screen);
          }
        });
      } catch (e) {
        console.warn('Websocket got closed in an unexpected way.', e);
      }
    },

    $_webSockets_connect: function (WSEndpoint, urlOverride = null, fallbackOverride, transmitMessage, screen) {
      try {
        let connectionUrl = wsProtocol + '://' + document.location.hostname + '/websockets/' + WSEndpoint + '/';

        if (urlOverride !== null) {
          connectionUrl = urlOverride;
        }

        if (!this.$data.$_webSockets_connections[WSEndpoint]) {
          this.$data.$_webSockets_connections[WSEndpoint] = {
            timer: undefined,
            connectAttempts: 0,
            connectedScreens: new Set().add(screen),
            socket: undefined,
          };
        }

        this.$data.$_webSockets_connections[WSEndpoint].socket = new WebSocket(connectionUrl);

        this.setupEventHandlers(WSEndpoint, transmitMessage, fallbackOverride, screen);

        if (!this.reconnectionsEnabled) {
          // After 5 seconds, on initial attempt lets assume there isn't going to be a connection, so we go to SSE.
          // If we don't do this, then we will need to wait around 40 seconds for the websocket timeout to occur #toolong
          setTimeout(() => {
            if (
              this.connectionStates[this.$data.$_webSockets_connections[WSEndpoint]?.socket?.readyState] ===
              'connecting'
            ) {
              this.$data.$_webSockets_connections[WSEndpoint]?.socket?.close();
              delete this.$data.$_webSockets_connections[WSEndpoint];
            }
          }, 5000);
        }
      } catch (error) {
        // If any part of the websocket connection fails, then we want to go straight to the fallback
        this.$_webSockets_onError(error, WSEndpoint, fallbackOverride);
      }
    },

    setupEventHandlers(WSEndpoint, transmitMessage, fallbackOverride, screen) {
      const connection = this.$data.$_webSockets_connections[WSEndpoint];

      connection.socket.onopen = () => this.handleOnOpenEvent(WSEndpoint, transmitMessage);

      connection.socket.onclose = (event) => this.handleOnCloseEvent(event, WSEndpoint, screen);

      connection.socket.onmessage = (message) => {
        // only ReportLog uses this
        if (WSEndpoint === 'report') {
          this.$_webSockets_onMessage(JSON.parse(message.data), WSEndpoint, message.type);
        } else {
          this.$_webSockets_onMessage(JSON.parse(message.data)['payload'], WSEndpoint, message.type);
        }
      };

      // Listen for errors
      connection.socket.onerror = (error) => this.$_webSockets_onError(error, WSEndpoint, fallbackOverride);
    },

    handleOnOpenEvent(WSEndpoint, transmitMessage) {
      // Now we know the system accepts websockets, reconnections can be allowed if there is a disconnect.
      // SSE will be used if onopen never fires.
      this.reconnectionsEnabled = true;

      // Send a message to backend if exists when opening connection
      if (typeof transmitMessage !== 'undefined') {
        this.$data.$_webSockets_connections[WSEndpoint].socket.send(transmitMessage);
      }

      // If this is a reconnection attempt it has succeeded so clear the polling timer
      if (this.$data.$_webSockets_connections[WSEndpoint].connectAttempts > 0) {
        console.info(`[Suade] Websockets | Connection to ${WSEndpoint} has been re-established`);
        this.removeTimer(WSEndpoint);
      }
    },

    handleOnCloseEvent(event, WSEndpoint, screen) {
      const connection = this.$data.$_webSockets_connections[WSEndpoint];

      // Abnormal socket closure, attempt reconnection (should look into the !event.wasClean property)
      if (this.reconnectionsEnabled && !event.wasClean) {
        console.warn(`[Suade] Websockets | Socket closed unexpectedly on ${WSEndpoint}`);

        connection.socket = {};

        // If we haven't estabilished a connection after 10 attempts (1min) assume
        // we won't reconnect, kill the timer and fallback to SSE
        if (connection.connectAttempts < 10) {
          connection.timer = setTimeout(() => {
            // if already in a "connecting" state, don't attempt to make another connection
            if (this.connectionStates[connection?.socket?.readyState] !== 'connecting') {
              console.info(`[Suade] Websockets | Attempting to reconnect to "${WSEndpoint}" ...`);

              connection.connectAttempts++;

              this.$_webSockets_connect(WSEndpoint, null, null, null, screen);
            }
          }, 6000);
        } else {
          this.removeTimer(WSEndpoint);
          this.$_webSockets_onError(`Could not re-connect to ${WSEndpoint}`, WSEndpoint, null);
        }
      }
    },

    removeTimer(WSEndpoint) {
      clearTimeout(this.$data.$_webSockets_connections[WSEndpoint].timer);
      this.$data.$_webSockets_connections[WSEndpoint].timer = undefined;
      this.$data.$_webSockets_connections[WSEndpoint].connectAttempts = 0;
    },

    $_webSockets_onMessage: function (message, WSEndpoint) {
      if (wsDebugMode) {
        console.warn(message);
      }

      // Firstly, if we receive a config update message, we should refresh the store config
      if (message['activity'] === 'config_updated') {
        const displayName = message.message.target.display_name;
        API.getSystemConfigByKey({config_key: displayName, _suppressNotify: true}).catch(() => {});
      }

      // See what screens have subscribed to this event
      this.$data.$_webSockets_subscribedScreens.forEach((screen) => {
        const endpoint = EventSockets[screen];
        if (Object.keys(endpoint).includes(WSEndpoint)) {
          if (
            Object.keys(endpoint[WSEndpoint]).includes(message['activity']) ||
            Object.keys(endpoint[WSEndpoint]).includes('all')
          ) {
            if (
              typeof this.$data.$_webSockets_subscribedEvents[screen] !== 'undefined' &&
              typeof this.$data.$_webSockets_subscribedEvents[screen][WSEndpoint] !== 'undefined'
            ) {
              this.$data.$_webSockets_subscribedEvents[screen][WSEndpoint](message);
            }
          }
        }
      });

      // Execute event
    },

    $_webSockets_onError: function (error, WSEndpoint, fallbackOverride) {
      console.warn('[Suade] Websockets | Connection failed. Falling back to SSE. Error:', error);

      // Please Note: These endpoints exist in the websockets docker container.
      // If we get a port closure or there is an issue with the container this fallback won't work
      // If that becomes an issue, these will need to be moved by backend.
      // Also be aware that ONLY the STATS endpoint currently works here.
      const eventSourceMap = {
        stats: '/websockets/stats.stream',
        events: '/websockets/events.stream',
        logs: '/websockets/logs.stream',
        jobs: '/websockets/jobs.stream',
      };

      if (typeof fallbackOverride === 'function') {
        fallbackOverride(WSEndpoint);
      } else if (typeof eventSourceMap[WSEndpoint] !== 'undefined') {
        const stream = new EventSource(eventSourceMap[WSEndpoint]);

        stream.addEventListener(WSEndpoint, (e) => {
          this.$_webSockets_onMessage(JSON.parse(e['data']), WSEndpoint);
        });

        this.$data.$_webSockets_eventSources[WSEndpoint] = stream;
      }
    },
  },
};
